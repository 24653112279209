<template>
    <el-row style="display: block;">
      <el-row>  <!--试卷头部分-->
          <div class="other_header" style="border-radius:8%;margin-top:0px;background:rgba(200, 222, 231,1);z-index: 200;position:fixed;height: 50px;line-height: 50px;text-align: center; color:#5D9CEC ;width:100%;">
            <el-row>
            <el-col :span="1">
              <img style="width:40px;height:40px;margin-top:5px;" src="../../assets/cts.png" />
            </el-col>
            <el-col :span="20">
              <span><font color="black">{{paperName}}</font></span>
            </el-col>
            <el-col :span="2">
              <el-button style="width:100px;" type="success" @click="do_go_back()">返回</el-button>
            </el-col>
            <el-col :span="1">
              <img style="width:40px;height:40px;" src="../../assets/user.png" />
            </el-col>
            </el-row>
          </div>
      </el-row>
      <el-row class="backstyle" >
        <el-col :span="5">
            <div style="position:fixed;width:300px;">
          <el-container  :style="height">
            <el-header class="header_style" style="line-height: 40px;height: 40px;"><font size="3">我的答题卡</font></el-header>
            <el-main class="content_style" style="margin-left: 0px;display: block;overflow-y: scroll;">
              
              <el-row v-for="(chp,c_index) in chapters" :key="c_index" style="margin-top: -20px;display:flex;flex-wrap: wrap;" >
                <div style="font-size:small;width:100%;">{{chp.title}}</div>
                <div v-for="(member,idx) in chp.arrProblems" :key="idx">
                  <el-button v-if="chp.rule_type!='E'" @click="Submit(member.seq_group)" class="button_style" :style="member.arrExams[0].show">{{member.seq_group}}</el-button>
                  <div v-else>
                    <el-button  @click="Submit(cmember.seq_no+member.seq_group-1)" class="button_style" v-for="(cmember,cidx) in member.arrExams" :key="cidx" :style="cmember.show">{{cmember.seq_no+member.seq_group-1}}</el-button>
                  </div>
                </div>
              </el-row>
            </el-main>
          </el-container>
          </div>
        </el-col>
        <el-col :span="16" style="min-height: 1px;">
                <!--中间试题区-->
                <el-container>
                    <el-main class="content_style" style="margin-left: 0px;">
                      <el-container>
                        
                        <el-main>
                          <div v-for="(chp,c_index) in chapters" :key="c_index">
                            <el-row style="margin-bottom:0px;"><font size="3"><b>{{chp.title}}</b></font></el-row>
                            <div v-for="(item,index) in chp.arrProblems" :key="index" >
                              <el-row v-if="chp.rule_type!='E'"  style="margin-bottom: 10px;margin-top:10px;"></el-row>
                              <el-row v-else  style="margin-bottom: 10px;margin-top:10px;">
                                <el-col :span="23" >
                                  <el-row style="rgb(228,228,228);">
                                    <span style="white-space:pre-wrap;line-height:26px;font-family: cursive;"><font size="3"> {{item.exams}}</font></span>
                                  </el-row>
                                </el-col>
                              </el-row>

                            <div  v-if="item.exam_type !=1532" >
                            <div  v-for="(member,midx) in item.arrExams" :key="midx" style="width:100%;">
                            <el-row :class="`classabc${member.seq_no+item.seq_group-1}`" style="margin-bottom: -8px;margin-top:-10px;">
                              
                              <el-col :span="2" >
                                <span v-if="member.exam_type==1528" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;单选</label></span>
                                <span v-else-if="member.exam_type==1530" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;判断</label></span>
                                <span v-else-if="member.exam_type==1529" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;多选</label></span>
                                <span v-else-if="member.exam_type==1531" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;填空</label></span>
                                <span v-else-if="member.exam_type==1652" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;问答</label></span>
                              </el-col>

                              <el-col :span="21" style="margin-left:-8px;">
                                
                                <el-row  style="rgb(228,228,228);">
                                 <span style="font-family: sans-serif;"><font size="3"> {{member.exams}}</font></span>
                                </el-row>
                                
                                
                                <el-row style="margin-top: -11px;" v-if="member.exam_type==1528||member.exam_type==1530">
                                  <!--单选题，判断题-->
                                  <el-radio-group v-model="member.arrAnswers"  disabled>
                                  <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                  
                                    <el-radio style="margin-top: 8px;" :label="citem.id" >
                                      <span style="white-space: pre-wrap;"><font size="3" color="#708090">{{citem.title}}</font></span>
                                     </el-radio>
                                    
                                  </div>
                                  </el-radio-group>
                                  
                                </el-row>
                                
                                <el-row style="margin-top: -8px;" v-if="member.exam_type==1529">
                                      <el-checkbox-group v-model="member.arrAnswers"  disabled>
                                        <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                          <el-checkbox style="margin-top: 5px;" :label="citem.id">
                                            <span style="white-space: pre-wrap;"><font size="3" color="#708090"> {{citem.title}}</font></span>
                                          </el-checkbox>
                                       </div> 
                                      </el-checkbox-group>
                                  
                                </el-row>
                                  <!--填空题，添加文本框-->
                                  <!-- <div v-else-if="member.arrExams[0].exam_type==1531"> 
                                    <span style="white-space: pre-wrap;"> <font size="3">{{citem}}&nbsp;&nbsp;&nbsp;&nbsp;</font></span>
                                    <span v-show="!member.edit">({{citem}}!)</span>
                                        <el-input v-show="member.edit" v-model="citem.myAnswer" style="width:200px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Fillchange(member.index,c_index,index,midx)"
                                        ></i>
                                  </div> -->
                                  <!--问答题,添加文本框-->
                                  <!-- <div v-else-if="member.arrExams[0].exam_type==1652"> 
                                      <span v-show="!member.edit">(点击输入答案!)</span>
                                        <el-input v-show="member.edit" v-model="member.myAnswer" type="textarea" rows="3" style="width:500px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Answerchange(member.index,c_index,index,midx)"
                                        ></i>
                                  </div> -->

                                <el-row v-if="member.my_score==0">
                                  <el-col :span="2" >
                                      <div>
                                        <img style="width: 50px; height: 50px" src="../../assets/false.png"/>
                                      </div>
                                  </el-col>
                                  <el-col :span="21" style="margin-left:-8px;margin-top:20px;">
                                        <span style="white-space: pre-wrap;"><font size="3" color="#3300FF"> 正确答案：{{member.answers}}</font></span>
                                        <span style="margin-left:30px;white-space: pre-wrap;"><font style="color:red;font-weight:bold;border-bottom:3px double red;" size="3"  color="#FF0000"> 得分：{{member.my_score}}</font></span>
                                  </el-col>
                                </el-row>
                                <el-row v-else>
                                  <el-col :span="2" >
                                      <div>
                                        <img v-if="member.answers.length==member.member_answers.length" style="width: 50px; height: 50px" src="../../assets/true.png"/>
                                        <img v-else style="width: 50px; height: 50px" src="../../assets/truefalse.png"/>
                                      </div>
                                  </el-col>
                                  <el-col :span="21" style="margin-left:-8px;margin-top:20px;">
                                        <span style="white-space: pre-wrap;"><font size="3" color="#3300FF"> 正确答案：{{member.answers}}</font></span>
                                        <span style="margin-left:30px;white-space: pre-wrap;"><font style="color:red;font-weight:bold;border-bottom:3px double red;" size="3"  color="#FF0000"> 得分：{{member.my_score/10}}</font></span>
                                  </el-col>
                                </el-row>
                                
                              </el-col>
                              
                            </el-row>
                            </div>
                            </div>
                            <div v-else>
                                <div  v-for="(member,midx) in item.arrExams" :key="midx" style="width:100%;">
                                    <el-row :class="`classabc${member.seq_no+item.seq_group-1}`" style="margin-bottom: -8px;margin-top:-10px;">
                                      
                                      <el-col :span="2" >
                                        <span v-if="member.exam_type==1528" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;单选</label></span>
                                        <span v-else-if="member.exam_type==1530" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;判断</label></span>
                                        <span v-else-if="member.exam_type==1529" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;多选</label></span>
                                        <span v-else-if="member.exam_type==1531" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;填空</label></span>
                                        <span v-else-if="member.exam_type==1652" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;问答</label></span>
                                      </el-col>

                                      <el-col :span="21" style="margin-left:-8px;">
                                        
                                        <el-row  style="rgb(228,228,228);">
                                        <span style="font-family: sans-serif;"><font size="3"> {{member.exams}}</font></span>
                                        </el-row>
                                        
                                        
                                        <el-row style="margin-top: -11px;" v-if="member.exam_type==1528||member.exam_type==1530">
                                          <!--单选题，判断题-->
                                          <el-radio-group v-model="member.arrAnswers"  >
                                          <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                          
                                            <el-radio style="margin-top: 8px;" :label="citem.id">
                                              <span style="white-space: pre-wrap;"><font size="3">{{citem.title}}</font></span>
                                            </el-radio>
                                            
                                          </div>
                                          </el-radio-group>
                                        </el-row>
                                        <el-row style="margin-top: -8px;" v-if="member.exam_type==1529">
                                              <el-checkbox-group v-model="member.arrAnswers" >
                                                <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                                  <el-checkbox style="margin-top: 5px;" :label="citem.id">
                                                    <span style="white-space: pre-wrap;"><font size="3"> {{citem.title}}</font></span>
                                                  </el-checkbox>
                                              </div> 
                                              </el-checkbox-group>
                                          
                                        </el-row>
                                          

                                        <el-row v-if="member.my_score==0">
                                  <el-col :span="2" >
                                      <div>
                                        <img style="width: 50px; height: 50px" src="../../assets/false.png"/>
                                      </div>
                                  </el-col>
                                  <el-col :span="21" style="margin-left:-8px;margin-top:20px;">
                                        <span style="white-space: pre-wrap;"><font size="3" color="#3300FF"> 正确答案：{{member.answers}}</font></span>
                                        <span style="margin-left:30px;white-space: pre-wrap;"><font style="color:red;font-weight:bold;border-bottom:3px double red;" size="3"  color="#FF0000"> 得分：{{member.my_score}}</font></span>
                                  </el-col>
                                </el-row>
                                <el-row v-else>
                                  <el-col :span="2" >
                                      <div>
                                        <img v-if="member.answers.length==member.member_answers.length" style="width: 50px; height: 50px" src="../../assets/true.png"/>
                                        <img v-else style="width: 50px; height: 50px" src="../../assets/truefalse.png"/>
                                      </div>
                                  </el-col>
                                  <el-col :span="21" style="margin-left:-8px;margin-top:20px;">
                                        <span style="white-space: pre-wrap;"><font size="3" color="#3300FF"> 正确答案：{{member.answers}}</font></span>
                                        <span style="margin-left:30px;white-space: pre-wrap;"><font style="color:red;font-weight:bold;border-bottom:3px double red;" size="3"  color="#FF0000"> 得分：{{member.my_score/10}}</font></span>
                                  </el-col>
                                </el-row>
                                        
                                        
                                      </el-col>
                                      
                                    </el-row>
                                </div>
                            </div>
                            </div>
                          </div>
                          
                        </el-main>
                      </el-container>
                    </el-main>
                </el-container>
            
        </el-col>

        <!--显示结果-->
        <el-col :span="3">
            <!--显示分数等信息-->
            <div >
                <el-container  :style="height">
                    <el-header class="header_style" style="line-height: 40px;height: 40px;"><font size="3">考试结果</font></el-header>
                    <el-main class="content_style" style="margin-left: 0px;display: block;">
                            <div><span><font size="3">本次考试分数</font></span></div>
                            <div style="margin-top:10px;margin-left:20px;"><font size="9" color="red"><span style="border-bottom:3px double black;">{{totalScore}}</span></font></div>
                            <div v-if="testAction==1" style="margin-top:10px;"><img style="float:left;" src="../../assets/Pass.png"></div>
                            <div v-else style="margin-top:10px;"><img style="float:left;" src="../../assets/NotPass.png"></div>
                            <!--
                            <div v-if="testAction==1" style="margin-top:10px;"><font color="green">恭喜您，您可以去实战了！</font></div>
                            <div v-else style="margin-top:10px;"><font color="red">很遗憾，您未通过考试！</font></div>-->
                            <div style="margin-top:50px;"><font size="3">总计耗时：</font></div>
                            <div style="margin-top:10px;margin-left:20px;"><font size="5" color="blue">{{dtLen}}分钟</font></div>
                    </el-main>
                </el-container>
            </div>
        </el-col>
        
        

      </el-row>
    </el-row>
</template>

<script>
import {QueryMemberPaperResult} from '../../api/data.js';
import global from '@/global';
export default{
    data(){
        return{
       height: {
            height: window.innerHeight-60 + 'px'
        },
        MemberTestId:0, //用户测试号 
        showModal: false,
        grades: 0,
        paperName:'',
        testAction:0, //是否通过考试
        totalScore:0, //总分数
        btm:'', //开始考试时间
        etm:'', //结束考试时间
        dtLen:'', //考试时长
        chapters:[] //等待存放后端数据的接收数组
       }
    },
    mounted(){
      this.update();//在html加载完成后进行，相当于在页面上同步显示后端数据
   },
    created(){
     this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function("event.returnValue=false");
        // 禁用选择
        document.onselectstart = new Function("event.returnValue=false");
      });
   },
   methods:{
        do_go_back()
        {
            //取消全屏
            //document.webkitCancelFullScreen(); //取消全屏
            this.$router.push({
                path:'/MyDo',
                name:'MyDo'
              });
            //window.close();
        },
        update(){
       //调用后台查询试卷信息
      let obj = {
        userId:global.machineId,
        testPaperId:this.$route.params.id,
        testPaperMemberId:this.$route.params.testId
      }
      let that = this; //很重要
      QueryMemberPaperResult(obj).then(function(res){
        
        that.paperName=res.data.paperName;
        
        that.totalScore = res.data.my_score/10;
        that.MemberTestId = res.data.MemberTestId;
        that.btm = res.data.btm;
        that.etm = res.data.etm;
        var dBtm = new Date(that.btm.replace(/-/g, "/"));
        var dEtm = new Date(that.etm.replace(/-/g, "/"));
        that.dtLen = Math.floor((dEtm.getTime()-dBtm.getTime())/(60*1000));
        that.testAction = that.totalScore>=res.data.qualified_score?1:0;
        //初始化checked数组
        var havaDoProblems = 0;
        for(var i=0;i<res.data.chapters.length;i++)
        {
              var chapter = new Object();
              chapter.title = res.data.chapters[i].title;
              chapter.rule_type = res.data.chapters[i].chp_type;
              chapter.arrProblems = new Array();
              that.chapters.push(chapter); //将对象添加到数组
              for(var j=0;j<res.data.chapters[i].datalist.length;j++)
              {
                  
                  
                      var member = res.data.chapters[i].datalist[j];
                      
                      var answer;
                      //将答案添加进对象中
                      if (member.exam_type!=1532) //非案例题
                      {
                          member.arrExams[0].arrResults = new Array();
                          member.arrExams[0].arrAnswers = new Array();
                          if (member.arrExams[0].my_score==0)
                            member.arrExams[0].show="background: #CC0000;";
                          else
                          {
                            if (member.arrExams[0].member_answers.length==member.arrExams[0].answers.length)
                              member.arrExams[0].show="background: #33CC33;";
                            else
                              member.arrExams[0].show="background: #FF9900;";
                          }
                            
                          //给用户选中答案赋值
                          if (member.exam_type==1529)
                            for(var n=0;n<member.arrExams[0].member_answers.length;n++)
                            {
                              member.arrExams[0].arrAnswers.push(member.arrExams[0].member_answers.charAt(n));
                            }
                          else
                            member.arrExams[0].arrAnswers = member.arrExams[0].member_answers;
                          member.arrExams[0].LastAnswer="";
                          havaDoProblems++;
                          switch(member.arrExams[0].choices)
                          {
                            case 2:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 3:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 4:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 5:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 6:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 7:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "G";
                              answer.title = member.arrExams[0].chg;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 8:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "G";
                              answer.title = member.arrExams[0].chg;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "H";
                              answer.title = member.arrExams[0].chh;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                          }
                      }
                      else //案例题
                      {
                          for(var m=0;m<member.choices;m++)
                          {
                              member.arrExams[m].arrResults = new Array();
                              member.arrExams[m].arrAnswers = new Array();
                              //member.arrExams[m].show="background: #D7D7D7;";
                              if (member.arrExams[m].my_score==0)
                                member.arrExams[m].show="background: #CC0000;";
                              else
                              {
                                if (member.arrExams[m].member_answers.length==member.arrExams[m].answers.length)
                                  member.arrExams[m].show="background: #33CC33;";
                                else
                                  member.arrExams[m].show="background: #FF9900;";
                              }
                              member.arrExams[m].LastAnswer="";
                              //给用户选中答案赋值
                              if (member.arrExams[m].exam_type==1529)
                                for(var ne=0;ne<member.arrExams[m].member_answers.length;ne++)
                                {
                                  member.arrExams[m].arrAnswers.push(member.arrExams[m].member_answers.charAt(ne));
                                }
                              else
                                member.arrExams[m].arrAnswers = member.arrExams[m].member_answers;
                              havaDoProblems++;
                              switch(member.arrExams[m].choices)
                              {
                                case 2:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 3:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 4:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 5:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 6:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 7:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "G";
                                  answer.title = member.arrExams[m].chg;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 8:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "G";
                                  answer.title = member.arrExams[m].chg;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "H";
                                  answer.title = member.arrExams[m].chh;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                              }
                          }
                      }
                      chapter.arrProblems.push(member);
                  
              }
            
        }
        
      });
     },
     Submit(idx){
       var wei = "classabc" + idx;
       var el = document.getElementsByClassName(wei)[0];
       console.log(el.offsetTop);
       this.$nextTick(function(){
         window.scrollTo({
            "behavior": "smooth",
            "top": el.offsetTop-10
         });
       });
     }
   }
}
</script>

<style lang="less" scoped>
  .header_style {
		margin-top: 0px;
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.other_header {
		background: rgb(255, 255, 255);
		font-size: 20px;
		font-weight: 400px;
		color: rgb(143, 201, 211);

	}

.backstyle{
  width: 100%;
  margin-top:40px;
  z-index: 100;
  color: rgb(22, 36, 39);
}
	.content_style {
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.button_style {
		margin-top: 10px;
		margin-left: 18px;
		margin-left: 10px;
		width: 50px;
		text-align: center;
    color: rgb(10, 10, 10);
    background:rgba(231, 236, 240,1);
	}

.button_style:hover{
  color:pink;
  background: rgb(186, 240, 222);
}

.button_style:active{
  color: rgb(248, 4, 4);
}

	.i_style {
		background: rgb(190, 209, 233);
		font-size: small;
		border-radius: 5%;
	}

// /deep/.el-radio__input.is-checked .el-radio__inner {
//   border-color: #ff9213;
//   background-color: #ff9213;
// }
// /deep/.el-radio__inner:hover {
//   border-color: #ff9213;
// }

// /deep/.el-radio__inner {
//   text-overflow: ellipsis;
//   white-space: normal;
//   line-height: 18px;
//   vertical-align: middle;
//   display: inline-block;
//   }
    .mask {
  background-color: rgb(199, 187, 187);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
    .pop {
  background-color: #fff;
  position: fixed;
  top: 300px;
  left: 500px;
  width: 400px;
  height:200px;
  z-index: 2
}
    .btn {
  background-color:rgb(93, 156, 236);;
  border-radius: 4px;
  border: 1px solid blue;
  padding: 4px 12px;
}
</style>